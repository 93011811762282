import React from 'react'


export default function ConfidentialityAgreementEn() {
    return (
        <div className='container mt-4 mb-4'>
            <b>Privacy Policy and Terms of Use</b>
            <br /><br />The privacy policy and terms of use stated here; It is valid for all mobile applications of EasyDivy published on Google Play Store and IOS App Store. By installing these applications on your mobile device, you are deemed to have accepted the privacy policy and terms of use contained in this text. If you do not accept these terms, do not install these applications on your mobile device.
            <br /><br />In our applications, the data entered is stored on encrypted Amazon servers in a way that only you can see and is not shared with third parties or institutions.
            <br /><br />Internet Usage Permissions are the permission required to send data from our application to encrypted servers and users need to define it. These permissions are not used to transfer any information from your phone against your will. The given internet access permission is only used for data and photo transfer of the forms you fill in the application.
            <br /><br />Our applications do not collect any personal information about you and do not make any requests from you in this direction.
            <br /><br />EasyDivy will always show due care and sensitivity to publish quality and useful applications. However, we make no commitment that the content in our applications will meet your expectations, be useful to you, or contain accurate information. We present the applications as they are. For this reason, you agree that you cannot hold EasyDivy responsible for any negative situation that may arise from our applications.
            <br /><br />EasyDivy makes every effort to take all possible precautions regarding the security of these applications and fulfills its obligations under the Google Play Developer Program Policies agreement. With this; Internet and digital environments are not sufficiently secure areas. Therefore, we do not make any guarantees that we will provide you with a 100% secure service.
            <br /><br />Our apps are only available in Google Play Store and IOS App Store. If these applications are located in another android or IOS store without our knowledge, EasyDivy cannot be held responsible for the downloads to be made from here.
            <br /><br />These applications may contain advertisements and links from third parties. You agree that you cannot hold EasyDivy responsible for the quality, content, security or any damages that may arise from these third party advertisements and links. You can learn how to edit your settings for ads published by Google from the ad settings page.
            <br /><br />All kinds of copyrights regarding all content consisting of audio, written and visual elements and software in these applications belong to EasyDivy. any of our apps or their copyrighted content; copying, duplication, republishing, disassembly, re-publication, etc. You agree that you will not take any action.
            <br /><br />You can send your comments and suggestions regarding the conditions stated here, to hello@genixo.ai e-mail address.
            <br /><br />EasyDivy; may make changes to the text of this Privacy Policy and Terms of Use. Changes made will take effect immediately. We indicate the date we made the change as the "last updated date" at the bottom.
            <br /><br />Last updated date:
            <br />10.11.2022
        </div>
    )
}
